import { Component } from '@angular/core';
import { WebComponent } from '@padspin/web';

@Component({
  selector: 'padspin-root',
  template: `<padspin-web></padspin-web>`,
  styleUrls: ['./app.component.css'],
  imports: [WebComponent],
})
export class AppComponent {}
