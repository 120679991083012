import { Component, OnDestroy } from '@angular/core';
import { SideNavService } from '../side-nav.service';
import { Subscription } from 'rxjs';
import { HeaderService } from '../header.service';
import { AuthService } from '../auth.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { map, filter } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import {
  PadspinButtonComponent,
  PadspinAnchorComponent,
} from '@padspin/ui-button';

@Component({
  selector: 'padspin-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css'],
  imports: [
    PadspinButtonComponent,
    PadspinAnchorComponent,
    RouterLink,
    AsyncPipe,
  ],
})
export class SideNavComponent implements OnDestroy {
  isOpen = false;
  subscription: Subscription;
  isSavedPadsVisible$ = this.headerService.savePadsVisible$;
  isViewingsVisible$ = this.headerService.viewingsVisible$;
  isBrowsePadsVisible$ = this.headerService.isBrowsePadsVisible$;
  isPostAPadVisible$ = this.headerService.isPostAPadVisible$;
  user$ = this.authService.user$;
  isTenantDashboardVisible$ = this.authService.user$.pipe(map((u) => !!u));
  isLandlordDashboardVisible$ = this.authService.user$.pipe(map((u) => !!u));
  isAdminDashboardVisible$ = this.authService.isAdmin$;

  currentRoute$ = this.router.events.pipe(
    filter((e) => e instanceof NavigationEnd),
    map(() => this.router.url)
  );

  isLeaseBreakLandingVisible$ = this.currentRoute$.pipe(
    map((route) => ['/leasebreak'].includes(route))
  );

  constructor(
    public readonly sideNavService: SideNavService,
    private readonly headerService: HeaderService,
    private readonly authService: AuthService,
    private readonly analytics: GoogleAnalyticsService,
    private readonly router: Router
  ) {
    this.subscription = this.sideNavService
      .isOpen()
      .subscribe((isOpen) => (this.isOpen = isOpen));
  }

  close(): void {
    this.sideNavService.toggleSideNav('close');
  }

  async logout(): Promise<void> {
    await this.authService.signOut();
    this.close();
  }

  async login(): Promise<void> {
    await this.authService.showOnboardingDialog();
    this.analytics.log('click_login');
    this.close();
  }

  async register(): Promise<void> {
    this.router.navigate(['/criteria']);
    this.analytics.log('sign_up');
    this.close();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
