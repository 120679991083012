import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'padspin-footer',
  template: `
    <nav>
      <ul>
        <li><a href="mailto:support@padspin.com">Contact</a></li>
        <li><a routerLink="/terms-of-service">Terms</a></li>
        <li><a href="https://www.indeed.com/cmp/Padspin-2">Careers</a></li>
        <li><a routerLink="/blog">Blog</a></li>
      </ul>
    </nav>
    <div class="copyright">
      <p>
        Copyright © 2023 Padspin ·
        <span>Born And Raised In NYC</span>
      </p>
    </div>
  `,
  styleUrls: ['./footer.component.css'],
  imports: [RouterLink],
})
export class FooterComponent {}
